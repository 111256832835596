import React from 'react';
import { Flex, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useColorMode } from "@chakra-ui/react";
import { Category } from "../../types";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface NavigationProps {
    categories: Category[];
    isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ categories, isOpen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { colorMode } = useColorMode();
    const idToken = useSelector((state: RootState) => state.auth.idToken);
    return (
        <Flex
            direction={isOpen ? "column" : "row"}
            gap="25px"
            lineHeight="25px"
            fontSize="15px"
            letterSpacing="1.2px"
            padding="0 11px"
            fontWeight="600"
            flexWrap="wrap"
        >
            {categories
                .filter((category) => !(category.code === 'HOME' && !idToken))
                .map((category) => {
                const isSelected = location.pathname === `/news/${category.redirectUrl}`;

                return (
                    <Text
                        key={category.id}
                        cursor="pointer"
                        color={colorMode === "dark" ? "dark.text" : "light.text"}
                        _hover={{
                            _after: {
                                width: "100%",
                            },
                        }}
                        position="relative"
                        _after={{
                            content: '""',
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: isSelected ? "100%" : "0%",
                            height: "2px",
                            backgroundColor: colorMode === "dark" ? "white" : "black",
                            transition: "width 0.3s ease-in-out",
                        }}
                        onClick={() => navigate(`/news/${category.redirectUrl}`)}
                        paddingBottom="5px"
                    >
                        {category.name}
                    </Text>
                );
            })}

        </Flex>
    );
};
