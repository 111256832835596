import {Box, Text, Card, useColorModeValue} from "@chakra-ui/react";

import { CgProfile } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

function Sidenav() {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const hoverColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const iconColor = useColorModeValue('black', 'white');

    const sidenavItems = [
        { icon: <CgProfile/>, label: "profile", href: "profile" },
        { icon: <BiEdit/>, label: "basic info", href: "basic-info" },
        // { icon: <FiSettings/>, label: "platform settings", href: "platform-settings" },
        // { icon: <MdLockOutline/>, label: "change password", href: "change-password" },
    ];

    const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
        const itemKey = `item-${key}`;

        return (
            <Box key={itemKey} as="li" pt={key === 0 ? 0 : 2}>
                <Text
                    as="a"
                    href={`#${href}`}
                    fontSize="md"
                    fontWeight="normal"
                    textTransform="capitalize"
                    display="flex"
                    alignItems="center"
                    px={4}
                    py={2}
                    borderRadius="md"
                    _hover={{
                        backgroundColor: hoverColor,
                    }}
                >
                    <Box mr={3} color={iconColor}>
                        {icon}
                    </Box>
                    {label}
                </Text>
            </Box>
        );
    });

    return (
        <Card
            borderRadius="lg"
            position="sticky"
            top="1%"
            p={4}
            bgColor={bgColor}
        >
            <Box as="ul" display="flex" flexDirection="column" p={0} m={0} listStyleType="none">
                {renderSidenavItems}
            </Box>
        </Card>
    );
}

export default Sidenav;
