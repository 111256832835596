
export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const toNormalCase = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const isNotEmptyString = (str: string) => {
    return str !== null && str !== undefined && str.trim() !== '';
}

export const isEmptyString = (str: string) => {
    return str == null || false || str.trim() == '';
}

export const validateEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
//    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const isNumberAndLengthEqualTo = (input: any, length: number) => {
    // Convert the input to a string to check its length
    const inputAsString = input.toString();
    return !isNaN(input) && inputAsString.length === length;
}
export const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
        return "passwordLength";
    }

    if (!password.split("").some((char) => char >= "0" && char <= "9")) {
        return "passwordNumber";
    }

    if (
        !password.split("").some((char) =>
            "!@#$%^&*()-_=+[]{}|;:',.<>?/`~".includes(char)
        )
    ) {
        return "passwordSpecialChar";
    }

    if (!password.split("").some((char) => char >= "A" && char <= "Z")) {
        return "passwordUpperCase";
    }

    if (!password.split("").some((char) => char >= "a" && char <= "z")) {
        return "passwordLowerCase";
    }

    return null; // Validation passed
};
