import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Avatar,
    Button,
    Flex,
    Stack,
    Text,
    Grid, Select, FormErrorMessage,
} from '@chakra-ui/react';

import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchUserDetails, updateUserDetails} from "../../store/user/userSlice";
import {LanguageSelector} from "../../components/Header/LanguageSelector";
import CountryPicker from "../../components/CountryPicker";
import {setLocale} from "../../store/locale/localeSlice";
import {Country, User} from "../../types";
import {SingleDatepicker} from "chakra-dayzed-datepicker";
import selectData from "../ProfileSettings/components/BasicInfo/data/selectData";
import {debounce} from "lodash";
import axios from "axios";

interface ProfileStepProps {
    handleStepDataChange: (updatedData: Partial<User>) => void
}

function ProfileStep({handleStepDataChange}: ProfileStepProps) {
    const dispatch = useAppDispatch();

    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const accessToken = useAppSelector((state: RootState) => state.auth.idToken);
    const user = useAppSelector((state: RootState) => state.user.user);
    const [bio, setBio] = useState(user?.bio || "");
    const [username, setUsername] = useState(user?.username || "");
    const [dateOfBirth, setDateOfBirth] = useState(user?.dateOfBirth ? new Date(user.dateOfBirth) : undefined);
    const [gender, setGender] = useState(user?.gender ? user.gender : "MALE");
    const [usernameError, setUsernameError] = useState<string | null>(null);

    const maxBioLength = 200;

    useEffect(() => {
        if (!user) {
            dispatch(fetchUserDetails(locale));
        }
        handleStepDataChange({bio: user?.bio, gender: user?.gender || gender, country: user?.country, dateOfBirth: user?.dateOfBirth});
    }, [dispatch, user]);

    const handleLocaleChange = (event: { target: { value: any; }; }) => {
        const newLocale = event.target.value;
        dispatch(setLocale(newLocale));
        localStorage.setItem('locale', newLocale);
        dispatch(updateUserDetails({language: newLocale}));
        window.location.reload();
    };

    const handleCountryChange = (country: Country) => {
        handleStepDataChange({country: country});
    }
    const checkUsernameAvailability = debounce(async (newUsername: string) => {
        if (!newUsername.trim()) {
            setUsernameError(null);
            return;
        }
        //TODO: Dispatch loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080'}/api/v1/user/check_username`, {
                headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
                params: { username: newUsername }
            });

            if (response.data) {
                setUsernameError(null);
            } else {
                setUsernameError("userNameError");
            }
        } catch (error) {
            setUsernameError("userNameCheckError");
        } finally {
            //TODO: Dispatch loading
        }
    }, 500);

    const handleUsernameChange = (newUsername: string) => {
        setUsername(newUsername);
        checkUsernameAvailability(newUsername);
        if(!usernameError) {
            handleStepDataChange({username: newUsername});
        }
    };

    return (
        <Flex justifyContent="center" alignItems="center">
            <Box
                textAlign="left"
            >
                <Heading size="lg" mb={4}>
                    <FormattedMessage id={"build_your_profile"} defaultMessage={"Build your profile"}/>
                </Heading>
                <Box mb={6}>
                    <FormattedMessage id={"about_yourself"}
                                      defaultMessage={"Tell us a little bit about yourself — this is how others will see you on NiSEE Community.\nYou’ll always be able to edit this later in your Settings."}/>
                </Box>

                <Box py={6}>
                    <Stack
                        borderRadius="lg"
                        w={{sm: '100%', md: '540px'}}
                        height={{sm: 'auto', md: 'auto'}}
                        direction={{base: 'column', md: 'row'}}
                    >
                        <Flex flex={1} justifyContent="center" alignItems="center">
                            <Avatar size="xl" name={user?.name || ""} src="https://bit.ly/broken-link"/>
                        </Flex>
                        <Stack
                            flex={1}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="left"
                            p={1}
                        >
                            <Heading fontSize={'2xl'} fontFamily={'body'}>
                                {user?.name || ""}
                            </Heading>

                            <Stack
                                width={'100%'}
                                mt={'2rem'}
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}>
                                <Button
                                    flex={1}
                                    fontSize={'sm'}
                                    rounded={'full'}
                                >
                                    <FormattedMessage id={"edit_profile_image"} defaultMessage={"Edit profile image"}/>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
                <FormControl id="username" mb={4} isInvalid={!!usernameError}>
                    <FormLabel><FormattedMessage id="username" defaultMessage="Username"/> </FormLabel>
                    <Input
                        value={username}
                        onChange={(e) => handleUsernameChange(e.target.value)}
                        placeholder="Enter your username"
                    />
                    {usernameError && (
                        <FormErrorMessage>
                            <FormattedMessage id={usernameError} />
                        </FormErrorMessage>
                    )}
                </FormControl>

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <FormControl>
                        <FormLabel><FormattedMessage id="language" defaultMessage="Language"/> </FormLabel>
                        <LanguageSelector locale={user?.language || locale} onLanguageChange={handleLocaleChange}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel><FormattedMessage id="country" defaultMessage="Country"/></FormLabel>
                        <CountryPicker selectedCountryCode={user?.country?.cca2}
                                       onChange={handleCountryChange}/>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel><FormattedMessage id="gender" defaultMessage="Gender"/></FormLabel>
                        <Select defaultValue={gender} onChange={(e) => {
                            const selectedGender = e.target.value as string;
                            setGender(selectedGender)
                            handleStepDataChange({gender: selectedGender})
                        }
                        }>
                            {selectData.gender.map((option) => (
                                <option key={option.toUpperCase()} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel><FormattedMessage id="birthDate" defaultMessage="Birth Date"/></FormLabel>
                        <SingleDatepicker
                            name="date-input"
                            date={dateOfBirth}
                            onDateChange={(date) => {
                                setDateOfBirth(date)
                                handleStepDataChange({dateOfBirth: date.toISOString().split('T')[0]})
                            }
                            }
                            propsConfigs={{
                                triggerBtnProps: { width: '100%' }
                            }}/>
                    </FormControl>

                </Grid>
                <FormControl id="bio" mb={6}>
                    <FormLabel><FormattedMessage id="bio" defaultMessage="Bio"/></FormLabel>
                    <Textarea
                        placeholder="Tell us a little about yourself"
                        size="sm"
                        defaultValue={bio}
                        onBlur={(e) => {
                            handleStepDataChange({bio: e.target.value})
                        }}
                        onChange={(e) => setBio(e.target.value)}
                    />
                    <Flex justifyContent="flex-end" mt={1}>
                        <Text fontSize="sm" color="gray.500">
                            {bio.length}/{maxBioLength}
                        </Text>
                    </Flex>
                </FormControl>
            </Box>
        </Flex>
    );
}

export default ProfileStep;
