import {
    Card, CardBody, CardHeader,
    Flex,
    Grid,
    Input,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {User} from "../../../types";
import {FormattedMessage} from "react-intl";
import {RootState} from "../../../store/store";
import {fetchFollowers} from "../../../store/user/userSlice";
import UserBox from "../../../components/UserBox";

interface FollowersProps {}

const Followers: FC<FollowersProps> = () => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const dispatch = useAppDispatch();
    const followers = useAppSelector((state: RootState) => state.user.followers);

    const [searchText, setSearchText] = useState<string>("");
    const [filteredFollowers, setFilteredFollowers] = useState<User[]>([]);

    useEffect(() => {
        if (followers.length === 0) {
            dispatch(fetchFollowers());
        }
    }, [dispatch, followers.length]);


    useEffect(() => {
        if (searchText === "") {
            setFilteredFollowers(followers);
        } else {
            const filtered = followers.filter((user) =>
                user.name?.toLowerCase().includes(searchText) || user.username?.toLowerCase().includes(searchText)
            );
            setFilteredFollowers(filtered);
        }
    }, [followers, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (value === "") {
            setFilteredFollowers(followers);
        } else {
            const filtered = followers.filter((user) =>
                user.name?.toLowerCase().includes(value) || user.username?.toLowerCase().includes(value)
            );
            setFilteredFollowers(filtered);
        }
    };

    return (
        <>
            <Card p='16px' bgColor={bgColor} height='50vh'>
                {followers.length === 0 ? (<></>) : (<CardHeader p='12px 5px' mb='12px'>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Input
                            placeholder="Search users..."
                            value={searchText}
                            onChange={handleSearch}
                            width="300px"
                        />
                    </Flex>
                </CardHeader>)}
                <CardBody px='5px'>
                    {followers.length === 0 ? (
                        <Text textAlign="center" fontSize="lg" color="gray.500">
                            <FormattedMessage id="no_followers" defaultMessage="You don't have any followers yet..." />
                        </Text>
                    ) : (
                        <Grid
                            key={filteredFollowers.length}
                            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                            gap='24px'>
                            {filteredFollowers.map((u) => (
                                <UserBox
                                    key={u.username}
                                    user={u}
                                    isSelected={false}
                                    onSelect={()=> {}}
                                />
                            ))}
                        </Grid>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default Followers;
