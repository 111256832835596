import React, {useEffect, useState} from 'react';
import {Box, VStack, HStack, Text, Button, Fade, Card, useColorModeValue, Flex} from '@chakra-ui/react';
import Projects from "./components/Projects";
import FollowingTags from "./components/FollowingTags";
import Followers from "./components/Followers";
import FollowingUsers from "./components/FollowingUsers";
import FollowingOrganisations from "./components/FollowingOrganisations";
import {useAppSelector} from "../../hooks/useAppSelector";
import {fetchUserDetails} from "../../store/user/userSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {RootState} from "../../store/store";

// Placeholder components for each menu item
const Posts = () => <Text fontSize="xl">Posts Content</Text>;

// Mapping menu items to their corresponding components
const menuItems = [
    { label: 'Posts', component: () => <Projects/> },
    { label: 'Followers', component: () => <Followers/> },
    { label: 'Following users', component: FollowingUsers },
    { label: 'Following tags', component: FollowingTags },
    { label: 'Following organizations', component: FollowingOrganisations },
];

const Profile = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const [selectedMenu, setSelectedMenu] = useState('Posts');
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const hoverColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const iconColor = useColorModeValue('black', 'white');
    const { user } = useAppSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchUserDetails(locale)); // Pass the locale as needed
    }, [dispatch]);

    // Get the component to render based on the selected menu item
    const SelectedComponent = menuItems.find(item => item.label === selectedMenu)?.component || Posts;

    return (
        <Box marginTop={5}>
            <Flex justifyContent="space-between" gap="2%" margin="auto" width="100%">
                <Box width="100%">
                    <Flex justifyContent="space-between">
                        <Box width="20%" >
                            <Card
                                borderRadius="lg"
                                position="sticky"
                                top="1%"
                                p={4}
                                bgColor={bgColor}
                            >
                                <Box as="ul" display="flex" flexDirection="column" p={0} m={0} listStyleType="none">
                                    {menuItems.map(({ label }, key) => {
                                        const itemKey = `item-${key}`;
                                        return (
                                            <Box key={itemKey} as="li" pt={key === 0 ? 0 : 2}>
                                                <Button
                                                    key={label}
                                                    variant="ghost"
                                                    fontWeight={label === selectedMenu ? 'bold' : 'normal'}
                                                    justifyContent="start"
                                                    onClick={() => setSelectedMenu(label)}
                                                    bg={label === selectedMenu ? 'gray.300' : 'transparent'}
                                                    _hover={{ bg: 'gray.200' }}
                                                    width="100%" // Make the button full width
                                                    textAlign="left" // Align text to the left
                                                >
                                                    {label}
                                                </Button>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Card>
                        </Box>
                        <Box width="80%" marginLeft={5}>
                            <Fade in>
                                <SelectedComponent />
                            </Fade>
                        </Box>
                    </Flex>
                    <>
                    </>
                </Box>

            </Flex>
        </Box>

    );
};

export default Profile;
