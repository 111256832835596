import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from "axios";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {User} from "../../types";
import {RootState} from "../store";
import {fetchAuthHeaders} from "../../utils/authHeaders";

interface UserState {
    isLoading: boolean;
    user?: User | null;
    followers: User[];
    following: User[];
    isError: boolean;
    errorMessage?: string;
}

const initialState: UserState = {
    isLoading: false,
    user: null,
    followers: [],
    following: [],
    isError: false,
    errorMessage: undefined
}

export const updateUserDetails = createAsyncThunk(
    'user/updateUserDetails',
    async (updatedUser: Partial<User>, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/update`, updatedUser,{
                headers: headers,
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to update user');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchUserDetails: AsyncThunk<User, string, { state: RootState }> = createAsyncThunk(
    'user/fetchUserDetails',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/user_details`, {},{
                headers: headers,
            });

            // Replace null values with empty strings in response.data
            const cleanedData = Object.fromEntries(
                Object.entries(response.data).map(([key, value]) => [key, value === null ? "" : value])
            );

            return cleanedData as unknown as User;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch user');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchFollowers: AsyncThunk<User[], void, { state: RootState }> = createAsyncThunk(
    'user/fetchFollowers',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/followers`,
                { headers }
            );
            return response.data as User[];
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch followers');
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchFollowing: AsyncThunk<User[], void, { state: RootState }> = createAsyncThunk(
    'user/fetchFollowing',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/user/following`,
                { headers }
            );
            return response.data as User[];
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch following');
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(updateUserDetails.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(updateUserDetails.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = { ...state.user, ...action.payload };
                state.isLoading = false;
            })
            .addCase(updateUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload as string;

            })
            .addCase(fetchUserDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<User>) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload as string;
            })
            .addCase(fetchFollowers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFollowers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.isLoading = false;
                state.followers = action.payload;
            })
            .addCase(fetchFollowers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload as string;
            })
            .addCase(fetchFollowing.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFollowing.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.isLoading = false;
                state.following = action.payload;
            })
            .addCase(fetchFollowing.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload as string;
            });
    }
});


export default userSlice.reducer;