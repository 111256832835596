import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Image,
    Text,
    Avatar,
    IconButton,
    Flex,
    Heading,
    SimpleGrid, Button, Link, Divider, Spinner,
} from "@chakra-ui/react";
import { FiBookmark, FiThumbsUp, FiMessageCircle } from "react-icons/fi";
import { News } from "../../types";
import { startLoading, stopLoading } from "../../store/loading/loadingSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import axios from "axios";
import {timeAgo} from "../../utils/dateUtils";
import {isNotEmptyString} from "../../utils/stringUtils";

const NewsDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { news_id } = useParams<{ news_id: string }>();
    const [newsData, setNewsData] = useState<News | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isUserPost, setIsUserPost] = useState(false);
    const [relatatedArticles, setRelatedArticles] = useState<News[]>([]);
    const [isLoadingRelated, setIsLoadingRelated] = useState<boolean>(false); // Loading for related articles


    useEffect(() => {
        const fetchNews = async () => {
            try {
                dispatch(startLoading());
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL || "http://localhost:8080/"}api/v1/news/${news_id}`
                );
                const data: News = await response.data;
                setNewsData(data);
                setIsUserPost(data.type === "USER_POST")
            } catch (err) {
                setError(err instanceof Error ? err.message : "Unknown error occurred.");
            } finally {
                dispatch(stopLoading());
            }
        };

        fetchNews();
    }, [news_id]);

    useEffect(() => {
        const fetchRelatedNews = async () => {
            try {
                setIsLoadingRelated(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL || "http://localhost:8080/"}api/v1/news/related/${news_id}`
                );
                const data: News[] = await response.data;
                setRelatedArticles(data);
            } catch (err) {
                console.error(err instanceof Error ? err.message : "Unknown error occurred.");
            } finally {
                setIsLoadingRelated(false);
            }
        };
        fetchRelatedNews();
    }, [news_id]);

    if (error) {
        return (
            <Flex justify="center" align="center" minH="100vh">
                <Text color="red.500" fontSize="lg">
                    {error}
                </Text>
            </Flex>
        );
    }

    if (!newsData) {
        return (
            <Flex justify="center" align="center" minH="100vh">
                <Text color="gray.500" fontSize="lg">
                    No news data found.
                </Text>
            </Flex>
        );
    }

    return (
        <SimpleGrid
            columns={{ base: 1, md: 12 }}
            spacing={6}
            mx="auto"
            p={4}
        >
            <Box
                position="sticky"
                top="20px" // Adjust as needed to leave some space from the top
                gridColumn={{ base: "span 1", md: "span 1" }}
                height="150px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <IconButton
                    icon={<FiThumbsUp />}
                    aria-label="Like"
                    variant="ghost"
                    size="lg"
                    mb={2}
                />
                <IconButton
                    icon={<FiMessageCircle />}
                    aria-label="Comment"
                    variant="ghost"
                    size="lg"
                    mb={2}
                />
                <IconButton
                    icon={<FiBookmark />}
                    aria-label="Bookmark"
                    variant="ghost"
                    size="lg"
                />
            </Box>

            {/* Main Content */}
            <Box
                gridColumn={{ base: "span 1", md: "span 8" }} // Takes 8 columns
                top="20px"
                borderRadius="md"
                boxShadow="md"
            >
                <Image
                    src={newsData.imageUrl}
                    alt="News Image"
                    borderTopRadius="lg"   // Rounded top corners
                    borderBottomRadius="0" // Flat bottom corners
                    w="full"
                    h={{ base: "200px", md: "400px" }}
                    objectFit="cover"
                    mb={4}
                    fallbackSrc="https://via.placeholder.com/800x400"
                />

                <Flex align="center" justify="space-between" mt={4} p={4} pl={8}>
                    <Flex align="center">
                        <Avatar
                            size="sm"
                            name={isUserPost ? newsData.author : newsData.sourceName }
                            mr={4} // Adds spacing between Avatar and text
                            src={isUserPost ? newsData.authorIconUrl : newsData.sourceIconUrl}
                        />
                        <Flex direction="column" justify="center">
                            <Text fontSize="sm" fontWeight="medium" textAlign="left"
                                  _hover={{ color: "blue.500" }}
                                  onClick={() => navigate(newsData?.profileLink || "/")}
                            >
                                {isUserPost ? newsData.author : newsData.sourceName}
                            </Text>
                            <Text fontSize="xs" fontWeight="light" color="black" textAlign="left">
                                {timeAgo(newsData.publishedAt)}
                            </Text>
                        </Flex>
                    </Flex>
                    <Button
                        size="sm"
                        colorScheme="black" // Chakra's color scheme for black
                        variant="outline"
                        _hover={{ bg: "black", color: "white" }} // Hover effect
                        onClick={() => setIsFollowing(!isFollowing)} // Toggles follow state
                    >
                        {isFollowing ? "Following" : "Follow"}
                    </Button>

                </Flex>



                <Heading as="h1" size="xl" mb={2} textAlign="left" p={4} pl={8} pr={8}>
                    {newsData.title}
                </Heading>
                <Text fontSize="lg" lineHeight="tall" mb={6} align="justify" p={4} pl={8} pr={8}>
                    {newsData.content}
                </Text>
            </Box>

            {/* Sidebar */}
            <Box
                gridColumn={{ base: "span 1", md: "span 3" }} // Takes 3 columns
                p={4}
                borderRadius="md"
                boxShadow="sm"
            >
                <Heading as="h2" size="md" mb={4} fontWeight="bold">
                    More from {isUserPost ? newsData.author : newsData.sourceName}
                </Heading>
                {isLoadingRelated ? (
                    <Flex justify="center" align="center" height="100px">
                        <Spinner size="md" color="gray.500" />
                    </Flex>
                ) : (
                    relatatedArticles.map((article) => (
                        <Box key={article.id} mb={6}>
                            <Link href={"/news/" + article.id} isExternal>
                                <Text fontSize="md" mb={1} textAlign="justify" noOfLines={3}>
                                    {article.title}
                                </Text>
                            </Link>
                            <Text fontSize="sm" color="gray.500" textAlign="left">
                                {article.tags.map((tag) => `#${tag.name} `)}
                            </Text>
                            <Divider mt={2} />
                        </Box>
                    ))
                )}
            </Box>
        </SimpleGrid>
    );
};

export default NewsDetails;
