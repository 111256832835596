import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import {
    Box, Flex, Collapse, Text, useDisclosure, IconButton
} from "@chakra-ui/react";
import { useColorMode, useMediaQuery } from "@chakra-ui/react";
import { Logo } from "../Logo";
import { RootState } from "../../store/store";
import { Navigation } from "./Navigation";
import { AuthButton } from "./AuthButton";
import { HamburgerMenu } from "./HamburgerMenu";
import {fetchCategories} from "../../store/category/categorySlice";
import {SearchDrawer} from "./SearchDrawer";
import { FaSearch } from 'react-icons/fa';
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {ProfileButton} from "./ProfileButton";
import {useAppSelector} from "../../hooks/useAppSelector";
import {refreshIdToken} from "../../store/auth/authSlice";
import RegionPicker from "../RegionPicker";

//@ts-ignore
const Header = ({ handleToggle, signOut }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { colorMode } = useColorMode();

    const locale = useSelector((state: RootState) => state.locale.locale);
    const categories = useAppSelector((state: RootState) => state.category.categories);
    const idToken = useSelector((state: RootState) => state.auth.idToken); // Get session from auth state

    const [isMenuOpen, setMenuOpen] = useState(false);

    // Search Drawer control
    const {
        isOpen: isSearchOpen,
        onOpen: onOpenSearch,
        onClose: onCloseSearch
    } = useDisclosure();

    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

    useEffect(() => {
        dispatch(refreshIdToken());
        dispatch(fetchCategories(locale));
    }, [dispatch, locale]);



    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <Box width="100%" margin="0" as="header">
            <Box display="flex" justifyContent="center" onClick={() => navigate("/")}>
                <Logo height="50px" />
            </Box>

            <Flex
                alignItems="center"
                borderBottom="1px solid grey"
                gap="10px"
                paddingX={[2, 4, 6]}
                flexWrap="wrap"
                top="0"
            >
                <>
                    <HamburgerMenu onClick={toggleMenu} />

                    <Collapse in={isMenuOpen || isLargerThan768} animateOpacity>
                        <Navigation categories={categories} isOpen={isMenuOpen} />
                    </Collapse>
                </>

                <Flex ml="auto" alignItems="center">
                    <RegionPicker onRegionChange={() => {}}/>
                    <IconButton
                        aria-label="Search"
                        icon={<FaSearch />}
                        onClick={onOpenSearch}
                        variant="transparent"
                        mr={5}
                        _hover={{
                            transform: "scale(1.1)", // Slight zoom effect
                            transition: "transform 0.2s ease-in-out" // Smooth zoom effect
                        }}
                        _focus={{
                            boxShadow: "none" // Removes default focus box shadow
                        }}
                    />
                    {idToken ? (
                        <ProfileButton />
                    ) : (
                        <AuthButton/>
                    )}
                </Flex>
            </Flex>

            {/* Drawer Component */}
            <SearchDrawer onClose={onCloseSearch} isOpen={isSearchOpen}/>
        </Box>
    );
};

export default Header;
