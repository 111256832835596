import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Grid,
    Input,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {Source} from "../../../types";
import {RootState} from "../../../store/store";
import {fetchSources, fetchSourcesForUser, modifyUserSources} from "../../../store/sources/sourceSlice";
import SourceBox from "../../../components/SourceBox";

interface FollowingOrganisationsProps {}
const FollowingOrganisations: FC<FollowingOrganisationsProps> = () => {
    const bgColor = useColorModeValue("light.cardBackground", "dark.cardBackground");
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState<string>("");
    const [filteredSources, setFilteredSources] = useState<Source[]>([]);
    const sources: Source[] = useAppSelector((state: RootState) => state.source.sources);
    const selectedSources = useAppSelector((state) => state.source.selectedSources);
    const locale = useAppSelector((state: RootState) => state.locale.locale);


    useEffect(() => {
        dispatch(fetchSourcesForUser(locale));
    }, [dispatch]);

    useEffect(() => {
        if (locale && sources.length === 0) {
            dispatch(fetchSources(locale));
        }
    }, [dispatch, locale, sources.length]);

    useEffect(() => {
        if (searchText === "") {
            setFilteredSources(sources);
        } else {
            const filtered = sources.filter((source) =>
                source.name?.toLowerCase().includes(searchText) || source.code?.toLowerCase().includes(searchText)
            );
            setFilteredSources(filtered);
        }
    }, [sources, searchText]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (value === "") {
            setFilteredSources(sources);
        } else {
            const filtered = sources.filter((source) =>
                source.name?.toLowerCase().includes(value) || source.code?.toLowerCase().includes(value)
            );
            setFilteredSources(filtered);
        }
    };

    const handleSourceSelect =  (source: Source, isSelected: boolean) => {
        const sourceCode = source.code;
        if (isSelected) {
            dispatch(modifyUserSources({actionType: "follow", sourceCode}));
        } else {
            dispatch(modifyUserSources({actionType: "unfollow", sourceCode}));
        }
    };

    return (
        <Card p="16px" bgColor={bgColor}>
            <CardHeader>
                <Flex justifyContent="space-between" alignItems="center">
                    <Input
                        placeholder="Search organisations..."
                        value={searchText}
                        onChange={handleSearch}
                        width="300px"
                    />
                </Flex>
            </CardHeader>
            <CardBody px="5px">
                <Grid
                    key={filteredSources.length} // This forces a re-render whenever filteredSources changes
                    templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                    templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                    gap="24px"
                >
                    {filteredSources.map((s) => (
                        <SourceBox
                            key={s.code}
                            source={s}
                            isSelected={selectedSources.some((selected) => selected.code === s.code)}
                            onSelectTag={handleSourceSelect}
                        />
                    ))}
                </Grid>
            </CardBody>
        </Card>
    );
};
export default FollowingOrganisations;