import {Avatar, Box, Image, Text, useColorModeValue, useToast} from "@chakra-ui/react";
import {User} from "../../types";
import avatar1 from "../../assets/img/avatars/avatar1.png";
import React from "react";

interface UserBoxProps {
    user: User;
    isSelected: boolean;
    onSelect: (user: User, isSelected: boolean) => void;
}

const UserBox: React.FC<UserBoxProps> = ({ user, isSelected, onSelect }) => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const borderColor = useColorModeValue("light.highlight", "dark.highlight");
    const selectedBorderColor = useColorModeValue('brand.600', 'brand.600');
    const textColor = useColorModeValue("inherit", isSelected ? "black" : "white");

    const toast = useToast();

    const handleSelect = async () => {
        debugger;
        try {
            onSelect(user, !isSelected); // Pass the event up to parent
        } catch (error) {
            toast({
                title: `Error selecting ${user.name}`,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

    return (
        <Box
            as="button"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={handleSelect}
            _hover={{ borderColor: borderColor, cursor: "pointer" }}
            borderColor={isSelected ? selectedBorderColor : "gray.200"}
            bg={isSelected ? "brand.50" : bgColor}
            width="100%"
        >
            {/* Icon */}
            <Avatar src={user.profilePicUrl} size="xl" shadow="sm" />

            {/* Code - Small Text */}
            <Text fontWeight="light" fontSize="sm" color={textColor} textAlign="center" mb={1}>
                @{user.username}
            </Text>

            {/* Name - Bold Text */}
            <Text fontWeight="bold" fontSize="md" color={textColor} textAlign="center">
                {user.name}
            </Text>
        </Box>
    );
};

export default UserBox;
