import {Box, Flex, Image, Text, useBreakpointValue} from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { News } from "../../types";
import FallbackImage from "../../components/FallbackImage";

type NewsCardProps = {
    news: News;
    noOfLines: number;
    imageHeight: string;
    fontWeight?: string;
};

const NewsCard: FC<NewsCardProps> = ({ news, noOfLines, imageHeight, fontWeight = "semibold" }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/news/${news.id}`);
    };

    if (!news) {
        return <Text>Loading...</Text>;
    }

    return (
        <Box
            as="article"
            role="button"
            aria-label={`News: ${news.title}`}
            w="100%"
            onClick={handleCardClick}
            cursor="pointer"
            _hover={{ transform: "scale(1.02)", transition: "transform 0.2s" }}
        >
            <Box rounded="md" shadow="sm" overflow="hidden">
                <Flex
                    direction={isMobile ? "column" : "column"} // Stack image and text vertically on mobile
                    align="center"
                    height="auto"
                >
                    <Image
                        src={news.imageUrl}
                        alt={`Picture of ${news.title}`}
                        fallbackSrc={"https://via.placeholder.com/300"}
                        borderRadius="md"
                        objectFit="cover"
                        w="100%"
                        h={isMobile ? "350" : imageHeight}
                    />
                    <Box mt={isMobile ? 2 : 3} px={2} w="full">
                        <Text
                            fontSize={isMobile ? "sm" : "lg"}
                            fontWeight={fontWeight}
                            as="h4"
                            lineHeight="short"
                            noOfLines={noOfLines}
                            textAlign="left"
                            _hover={{ textDecoration: "underline" }}
                        >
                            {news.title}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default NewsCard;
