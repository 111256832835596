import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counter/counterSlice'
import categoryReducer from './category/categorySlice'
import loadingReducer from './loading/loadingSlice'
import latestNewsReducer from './latestNews/latestNewsSlice'
import topNewsReducer from './topNews/topNewsSlice'
import localeReducer from './locale/localeSlice'
import authReducer from './auth/authSlice'
import homeFeedNewsReducer from "./homeFeedNews/homeFeedNewsSlice";
import categoriesNewsFeedReducer from "./categoryNewsFeed/categoryNewsFeedSlice";
import topNewsByCategoryReducer from "./topNewsByCategory/topNewsByCategorySlice";
import stockIndexPerformanceReducer from './stockIndexPerformance/stockIndexPerformanceSlice';
import userReducer from './user/userSlice';
import countryReducer from './country/countrySlice';
import regionReducer from './region/regionSlice';
import tagReducer from './tags/tagSlice';
import sourceReducer from './sources/sourceSlice';
import postReducer from './post/postSlice';
import userPostsReducer from './userPosts/userPostSlice';
import s3Reducer from './s3/s3Slice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        category: categoryReducer,
        country: countryReducer,
        loading: loadingReducer,
        latestNews: latestNewsReducer,
        topNews: topNewsReducer,
        homeFeedNews: homeFeedNewsReducer,
        categoriesNewsFeed: categoriesNewsFeedReducer,
        topNewsByCategory: topNewsByCategoryReducer,
        locale: localeReducer,
        auth: authReducer,
        stockIndexPerformance: stockIndexPerformanceReducer,
        user: userReducer,
        tag: tagReducer,
        source: sourceReducer,
        region: regionReducer,
        post: postReducer,
        userPosts: userPostsReducer,
        s3: s3Reducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch