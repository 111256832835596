import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    useBreakpointValue,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {confirmPasswordThunk, forgotPasswordThunk} from "../../store/auth/authSlice";
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";
import {isNumberAndLengthEqualTo, validateEmail, validatePassword} from "../../utils/stringUtils";

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [resetStep, setResetStep] = useState(false);
    const [code, setResetCode] = useState("");
    const [codeError, setCodeError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();

    const handleForgotPassword = async () => {
        setEmailError(null);
        setCodeError(null);
        setPasswordError(null);
        if (!email) {
            setEmailError("Email is required.");
            return;
        }
        if (!validateEmail(email)) {
            setEmailError("Invalid email format.");
            return;
        }

        try {
            await dispatch(forgotPasswordThunk(email)).unwrap();
            showToast({
                title: "Success!",
                description: "Password reset instructions have been sent to your email.",
                status: "success",
                position: "bottom-right",
            });
            setResetStep(true);
        } catch (error) {
            console.error(error);
            setResetStep(false);
            setEmailError("failedSendPasswordReset")
        }
    };

    const handleResetPassword = async () => {
        setEmailError(null);
        setCodeError(null);
        setPasswordError(null);

        // Validate form inputs
        if (!email || !newPassword || !confirmPassword || !code) {
            setPasswordError("allFieldsRequired");
            return;
        }

        if (!validateEmail(email)) {
            setEmailError("invalidEmail");
            return;
        }

        if (newPassword !== confirmPassword) {
            setPasswordError("passwordMismatch");
            return;
        }

        if(!isNumberAndLengthEqualTo(code, 6)) {
            setCodeError("invalidCode");
            return;
        }

        const passwordValidationError = validatePassword(newPassword);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        }

        try {
            await dispatch(confirmPasswordThunk({ username: email, code, newPassword })).unwrap();
            showToast({
                title: "Success!",
                description: "Password reset successfully. You can now log in with your new password.",
                status: "success",
                position: "bottom-right",
            });
            navigate("/login");
        } catch (error) {
            console.error(error);
            // @ts-ignore
            setPasswordError(error.message || "failedPasswordReset");
        }
    };

    const formSpacing = useBreakpointValue({ base: 4, md: 6 });

    return (
        <Flex
            width="100%"
            height={{ base: "100%", md: "100%" }}
            justifyContent="center"
            alignItems="center"
        >
            <Flex
                width={{ base: "100%", md: "90%", lg: "80%" }}
                maxWidth="1200px"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
                bg="white"
                overflow="hidden"
            >
                {/* Left Side: Form Section */}
                <Box flex="1" padding="40px" width="100%">
                    <Text fontSize="2xl" fontWeight="bold" mb={8} textAlign="left">
                        <FormattedMessage id="resetPassword" defaultMessage="Reset Password" />
                    </Text>
                    <Stack spacing={formSpacing}>
                        <FormControl id="email" isInvalid={!!emailError}>
                            <FormLabel>
                                <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                            </FormLabel>
                            <Input
                                type="email"
                                value={email}
                                required={true}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    setResetStep(false)
                                    setResetCode("")
                                    setNewPassword("")
                                    setConfirmPassword("")
                                }}
                                focusBorderColor="grey.400"
                            />
                            {emailError && (
                                <FormErrorMessage>
                                    <FormattedMessage id={emailError} />
                                </FormErrorMessage>
                            )}
                        </FormControl>

                        {resetStep && (
                            <>
                            <FormControl id="code" isInvalid={!!codeError}>
                                <FormLabel>
                                    <FormattedMessage id="resetCode" defaultMessage="Reset Code" />
                                </FormLabel>
                                <Input
                                    type="text"
                                    value={code}
                                    onChange={(e) => setResetCode(e.target.value)}
                                    focusBorderColor="grey.400"
                                />
                                {codeError && (
                                    <FormErrorMessage>
                                        <FormattedMessage id={codeError} />
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                                <FormControl id="newPassword" isInvalid={!!passwordError}>
                                    <FormLabel>
                                        <FormattedMessage id="newPassword" defaultMessage="New Password" />
                                    </FormLabel>
                                    <Input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        focusBorderColor="grey.400"
                                    />
                                </FormControl>
                                <FormControl id="confirmPassword" isInvalid={!!passwordError}>
                                    <FormLabel>
                                        <FormattedMessage id="confirmPassword" defaultMessage="Confirm New Password" />
                                    </FormLabel>
                                    <Input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        focusBorderColor="grey.400"
                                    />
                                    {passwordError && (
                                        <FormErrorMessage>
                                            <FormattedMessage id={passwordError} />
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                         </>
                        )}
                        {resetStep ? (
                            <Button
                                bg="black"
                                color="white"
                                size="lg"
                                _hover={{ bg: "gray.800" }}
                                onClick={handleResetPassword}
                            >
                                <FormattedMessage id="resetPassword" defaultMessage="Reset Password" />
                            </Button>

                        ) : (
                            <Button
                                bg="black"
                                color="white"
                                size="lg"
                                _hover={{ bg: "gray.800" }}
                                onClick={handleForgotPassword}
                            >
                                <FormattedMessage id="sendResetLink" defaultMessage="Send Reset Link" />
                            </Button>
                        )}
                    </Stack>
                </Box>

                {/* Right Side: Info Section */}
                <Box
                    flex="1"
                    bg="black"
                    padding="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="lg"
                >
                    <Stack spacing={4} mt={20} mb={20}>
                        <Text fontSize="xl" fontWeight="bold" color="white" textAlign="left">
                            <FormattedMessage
                                id="resetInfo"
                                defaultMessage="Forgot your password?"
                            />
                        </Text>
                        <Text color="white" textAlign="left">
                            <FormattedMessage
                                id="resetInstructions"
                                defaultMessage="Enter your email to receive reset instructions."
                            />
                        </Text>
                    </Stack>
                </Box>
            </Flex>
        </Flex>
    );
};
