import {
  Button, Card, CardBody, CardHeader,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect} from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {fetchPostsOfCurrentUser} from "../../../store/userPosts/userPostSlice";
import {News} from "../../../types";
import NewsCard from "../../../components/NewsCard/NewsCard";
import {FormattedMessage} from "react-intl";

interface ProjectsProps {}

const Projects: FC<ProjectsProps> = () => {
  const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { posts } = useAppSelector((state) => state.userPosts);

  useEffect(() => {
    dispatch(fetchPostsOfCurrentUser());
  }, [dispatch]);



  const handleClick = () => {
    navigate("/create-article");
  };

  return (
      <>
        <Card p='16px' bgColor={bgColor}>
          <CardBody px='5px'>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                gap='24px'>
              <Button
                  p='0px'
                  bg='transparent'
                  color='gray.500'
                  border='1px solid lightgray'
                  onClick={handleClick}
                  borderRadius='15px'
                  minHeight={{ sm: "200px", md: "200px" }}>
                <Flex direction='column' justifyContent='center' align='center'>
                  <Icon as={FaPlus} fontSize='lg' mb='12px' />
                  <Text fontSize='lg' fontWeight='bold'>
                    Create a New Article
                  </Text>
                </Flex>
              </Button>
              {posts.map((post: News) =>
             (
                 <NewsCard news={post} noOfLines={2} imageHeight={'300'} fontWeight={"normal"} />
             )
              )}
            </Grid>
          </CardBody>
        </Card>
      </>
  );
};

export default Projects;
