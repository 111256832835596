import {
    Box,
    Container,
    Stack,
    Text,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    useTheme,
    Flex,
    Image,
} from '@chakra-ui/react';
import { Logo } from "./Logo";
import { FormattedMessage } from "react-intl";
import React from "react";
import SocialLinks from "./SocialLinks";
import AboutUsDrawer from "../pages/About/AboutUsDrawer";

export default function Footer() {
    const theme = useTheme();
    const accentColor = theme.colors.gray;
    const { colorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <footer style={{ width: '100%' }}>
            <Box
                as="footer"
                bg={colorMode === "dark" ? "dark.footerBackground" : "light.footerBackground"}
                color={colorMode === "dark" ? "dark.text" : "light.text"}
                width="100%"
                margin="auto"
                marginTop="25px"
            >
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    spacing={4}
                    justify={'center'}
                    align={'center'}
                >
                    {/* Responsive Flex container for Logo and Badges */}
                    <Flex
                        direction={{ base: "column", md: "row" }}
                        align="center"
                        justify={{ base: "center", md: "space-between" }}
                        width="100%"
                        maxW="6xl"
                        gap={{ base: 4, md: 0 }}
                    >
                        {/* Logo */}
                        <Logo height={50} />

                        {/* Play Store and Apple Store Badges */}
                        <Flex
                            gap={4}
                            direction={{ base: "row", md: "row" }}
                            justifyContent={{ base: "center", md: "flex-start" }}
                        >
                            <Image
                                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                                alt="Google Play Store"
                                height={50}
                                cursor="pointer"
                                _hover={{ opacity: 0.8 }}
                            />
                            <Image
                                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us"
                                alt="Apple App Store"
                                height={50}
                                cursor="pointer"
                                padding={2}
                                _hover={{ opacity: 0.8 }}
                            />
                        </Flex>
                    </Flex>
                </Container>

                <Box
                    borderTopWidth={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                >
                    <Container
                        as={Stack}
                        maxW={'6xl'}
                        py={4}
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4}
                        justify={{ base: 'center', md: 'space-between' }}
                        align={{ base: 'center', md: 'center' }}
                    >
                        <Text
                            color={accentColor}
                            fontSize="11.5px"
                            lineHeight="24px"
                            letterSpacing="-0.09px"
                            _hover={{ textDecoration: "underline", cursor: "pointer" }}
                        >
                            <FormattedMessage id="copyright" defaultMessage="Copyright© 2024, NiSee.com" />
                        </Text>

                        <Stack direction={'row'} spacing={6}>
                            <Box as="button" onClick={onOpen}>
                                <Text
                                    paddingRight="10px"
                                    color={accentColor}
                                    padding="0 7px"
                                    fontSize="11.5px"
                                    lineHeight="24px"
                                    letterSpacing="-0.09px"
                                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    <FormattedMessage id="aboutUs" defaultMessage="About Us" />
                                </Text>
                            </Box>
                            <Box as="a" href={'#'}>
                                <Text
                                    paddingRight="10px"
                                    color={accentColor}
                                    padding="0 7px"
                                    fontSize="11.5px"
                                    lineHeight="24px"
                                    letterSpacing="-0.09px"
                                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    <FormattedMessage id="termsOfUse" defaultMessage="Terms of Use" />
                                </Text>
                            </Box>
                            <Box as="a" href={'#'}>
                                <Text
                                    paddingRight="10px"
                                    color={accentColor}
                                    padding="0 7px"
                                    fontSize="11.5px"
                                    lineHeight="24px"
                                    letterSpacing="-0.09px"
                                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    <FormattedMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
                                </Text>
                            </Box>
                            <Box as="a" href={'#'}>
                                <Text
                                    paddingRight="10px"
                                    color={accentColor}
                                    padding="0 7px"
                                    fontSize="11.5px"
                                    lineHeight="24px"
                                    letterSpacing="-0.09px"
                                    _hover={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    <FormattedMessage id="contacts" defaultMessage="Contacts" />
                                </Text>
                            </Box>
                        </Stack>

                        <SocialLinks />
                    </Container>
                </Box>
            </Box>

            <AboutUsDrawer isOpen={isOpen} onClose={onClose} />
        </footer>
    );
}
