import {useMatch, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {News, User} from "../../types";
import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    useColorModeValue, Button, SimpleGrid, IconButton, Grid, GridItem, VStack, Divider, HStack, Icon,
} from '@chakra-ui/react'
import axios from "axios";
import {startLoading, stopLoading} from "../../store/loading/loadingSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {FiBookmark, FiMessageCircle, FiThumbsUp} from "react-icons/fi";
import {FaBuilding, FaComments, FaFileAlt, FaHashtag} from "react-icons/all";

function UserDetailsPage() {
    const { id } = useParams();
    const [user, setUser] = useState<User | null>(null);
    const dispatch = useAppDispatch();

    const [isFollowing, setIsFollowing] = useState(false);

    const toggleFollow = () => {
        setIsFollowing((prev) => !prev);
    };

    const isUserRoute = useMatch("/user/:id");
    const isOrganisationRoute = useMatch("/organisation/:id");

    const type = isUserRoute ? "user" : isOrganisationRoute ? "organisation" : null;



    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                dispatch(startLoading());
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL || "http://localhost:8080/"}api/v1/${type}/${id}`
                );
                const data: User = await response.data;
                setUser(data);
            } catch (err) {
                console.error(err instanceof Error ? err.message : "Unknown error occurred.");
            } finally {
                dispatch(stopLoading());
            }
        };

        fetchUserDetails();
    },  [id,type]);


    return (
        <Box minH="100vh" p="8">
            <Center py={6}>
                <Box
                    maxW={'100%'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow={'2xl'}
                    rounded={'lg'}
                    p={6}
                    pt={16} // Add padding top to make space for Avatar
                    textAlign={'center'}
                    position="relative" // Required for absolute positioning
                >
                    {/* Avatar */}
                    <Avatar
                        size={'xl'}
                        src={user?.profilePicUrl}
                        position={'absolute'} // Position avatar outside the card
                        top={-12} // Move it upwards
                        left={'50%'} // Center horizontally
                        transform={'translateX(-50%)'} // Adjust for exact centering
                        border={'4px solid'} // Optional: Add border for a nice look
                        borderColor={useColorModeValue('white', 'gray.900')} // Matches card background
                    />

                    <Button
                        position="absolute"
                        top={4}
                        right={4}
                        colorScheme={isFollowing ? "gray" : "teal"}
                        size="sm"
                        onClick={toggleFollow}
                    >
                        {isFollowing ? "Following" : "Follow"}
                    </Button>

                    {/* Content */}
                    <Heading fontSize={'2xl'} fontFamily={'body'} mt={2}>
                        {user?.name}
                    </Heading>
                    <Text fontWeight={600} color={'gray.500'} mb={4}>
                        @{user?.username}
                    </Text>
                    <Text
                        textAlign={'center'}
                        color={useColorModeValue('gray.700', 'gray.400')}
                        px={3}
                    >
                        {user?.bio}
                    </Text>
                </Box>
            </Center>
                {/* Two-Column Grid */}
                <Grid templateColumns="250px 1fr" gap={6} mt={8}>
                    {/* First Column (Small Width) */}
                    <GridItem>
                        <VStack align="start" spacing={4}>
                            {/* Posts Published */}
                            <HStack>
                                <Icon as={FaFileAlt} boxSize={5} />
                                <Text>9 posts published</Text>
                            </HStack>

                            {/* Comments Written */}
                            <HStack>
                                <Icon as={FaComments} boxSize={5} />
                                <Text>0 comments written</Text>
                            </HStack>

                            {/* Tags Followed */}
                            <HStack>
                                <Icon as={FaHashtag} boxSize={5} />
                                <Text>4 tags followed</Text>
                            </HStack>

                            {/* Organisations Followed */}
                            <HStack>
                                <Icon as={FaBuilding} boxSize={5} />
                                <Text>5 organisations followed</Text>
                            </HStack>

                            <Divider borderColor="gray.600" />
                        </VStack>
                    </GridItem>

                    {/* Second Column (Main Content) */}
                    <GridItem>
                        <Box bg={useColorModeValue("gray.50", "gray.700")} p={6} rounded="md">
                            <Heading size="md" mb={4}>
                                Main Content
                            </Heading>
                            <Text>
                                This is the main content area. You can add any detailed information, lists,
                                or other components here. The second column takes up the remaining width
                                after the sidebar.
                            </Text>
                        </Box>
                    </GridItem>
                </Grid>

        </Box>
    );
}

export default UserDetailsPage;
