import React, { FC, useEffect, useState } from 'react';
import {Box, Heading, HStack, Icon, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../store/store";
import { groupBy } from 'lodash';
import { useAppDispatch } from "../../hooks/useAppDispatch";
import NewsCard from "../NewsCard/NewsCard";
import { fetchCategoryFeedNews } from "../../store/categoryNewsFeed/categoryNewsFeedSlice";
import {News} from "../../types";
import {ChevronRightIcon} from "@chakra-ui/icons";

interface HeadlinesProps {
    category: string,
    numLines?: number
}

const HeadlinesCardView: FC<HeadlinesProps> = ({ category, numLines = 2 }: HeadlinesProps) => {
    const [mainList, setMainList] = useState<News[]>([]);
    const [groupedBySubCategory, setGroupedBySubCategory] = useState<{ [key: string]: News[] }>({});
    const [remainingList, setRemainingList] = useState<News[]>([]);

    const news = useAppSelector((state: RootState) => state.categoriesNewsFeed.data[category] || []);
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const textColor = useColorModeValue('light.text', 'dark.text');

    useEffect(() => {
        dispatch(fetchCategoryFeedNews({ category, locale, page: 0 }));
    }, [dispatch, category, locale]);

    useEffect(() => {
        const ungroupedListItems = news.filter(item => item.subCategoryDisplay === null);
        const grouped = groupBy(news.filter(item => item.subCategoryDisplay !== null), 'subCategoryDisplay');

        Object.keys(grouped).forEach(key => {
            if (grouped[key].length > 4) {
                const [topItems, extraItems] = [grouped[key].slice(0, 4), grouped[key].slice(4)];
                grouped[key] = topItems;
                ungroupedListItems.push(...extraItems);
            }
        });

        const [mainListItems, remainingListItems]  = [ungroupedListItems.slice(0, 9), ungroupedListItems.slice(9)];

        setMainList(mainListItems);
        setRemainingList(remainingListItems);
        setGroupedBySubCategory(grouped);
    }, [news]);

    return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {mainList.map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>



            {Object.keys(groupedBySubCategory).map((subCategory, index) => (
                <Box key={index}>
                    <HStack spacing={2} align="center">
                        <Heading
                            as="h2"
                            fontSize="2xl"
                            borderLeftWidth="6px"
                            borderLeftColor={textColor}
                            color={textColor}
                            display="flex"
                            alignItems="center"
                            pl={4}
                            marginTop={10}
                            marginBottom={5}
                        >
                            {subCategory}
                        </Heading>
                    </HStack>

                    {/*<Heading as="h2" size="lg" marginTop={5} marginBottom={2}>{subCategory}</Heading>*/}
                    <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
                        {groupedBySubCategory[subCategory].map((item, idx) => (
                            <Box key={idx}>
                                <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} fontWeight={"normal"} />
                            </Box>
                        ))}
                    </SimpleGrid>
                </Box>
            ))}

            {/*<SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>*/}
            {/*    {remainingList.map((item, index) => (*/}
            {/*        <Box key={index}>*/}
            {/*            <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />*/}
            {/*        </Box>*/}
            {/*    ))}*/}
            {/*</SimpleGrid>*/}

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {remainingList.slice(0, 12).map((item, index) => ( // Limit to 12 items
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default HeadlinesCardView;
