import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Grid,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    VStack,
    Button,
    Image,
    Text, Divider,
} from "@chakra-ui/react";
import { Logo } from "../Logo";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";
import {formatDate} from "../../utils/dateUtils";

// Define the API response structure
interface SearchResult {
    id: string;
    title: string;
    description: string;
    images: string[];
    publishedAt: string;
}

interface SearchDrawerProps {
    onClose: () => void;
    isOpen: boolean;
}
export const SearchDrawer: React.FC<SearchDrawerProps> = ({ onClose, isOpen }) => {
    const [query, setQuery] = useState<string>('');
    const [results, setResults] = useState<SearchResult[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [fromIdx, setFromIdx] = useState<number>(0);
    const [toIdx, setToIdx] = useState<number>(0);
    const navigate = useNavigate();

    // Fetch search results from API
    const fetchSearchResults = async (query: string, page: number) => {
        if (!query) return;

        try {
            const response = await fetch(`http://localhost:8080/api/v1/search?query=${query}&page=${page}`);
            const data = await response.json();
            setResults(data.content);
            setTotalPages(data.totalPages);
            setTotalElements(data.totalElements);
            setFromIdx((data.pageable.pageNumber-1) * data.pageable.pageSize);
            setToIdx(((data.pageable.pageNumber-1) * data.pageable.pageSize) + data.numberOfElements);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    // Debounced search results fetch
    const debouncedFetchResults = useCallback(
        debounce((query: string) => {
            fetchSearchResults(query, 1);
        }, 500), // Adjust debounce time (500ms) as needed
        []
    );

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);
        debouncedFetchResults(value); // Use the debounced function
    };

    // Handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchSearchResults(query, page);
    };

    // Cleanup debounce on component unmount
    useEffect(() => {
        return () => {
            debouncedFetchResults.cancel();
        };
    }, [debouncedFetchResults]);

    // Clear search state when the drawer closes
    const handleDrawerClose = () => {
        setQuery('');
        setResults([]);
        setCurrentPage(1);
        setTotalPages(1);
        onClose(); // Call the parent-provided onClose handler
    };

    return (
        <Drawer placement="right" size={"full"} onClose={handleDrawerClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Box display="flex" justifyContent="center" marginTop="0">
                        <Logo onClick={() => navigate("/")} height={[30, 50, 70]} marginBottom={[4, 4, 4]} />
                    </Box>
                </DrawerHeader>
                <DrawerBody>
                    <Box textAlign="center" fontSize="xl">
                        <Grid minH="100vh" p={3}>
                            <VStack spacing={8}>
                                <Box width={{ base: "95%", md: "92%" }}>
                                    <InputGroup>
                                        <Input
                                            placeholder="Search NiSee..."
                                            value={query}
                                            onChange={handleInputChange}
                                        />
                                        <InputRightElement>
                                            <Icon as={FaSearch} color="gray.300" onClick={() => fetchSearchResults(query, 1)} />
                                        </InputRightElement>
                                    </InputGroup>
                                </Box>

                                {/* Display search results */}
                                <Box width={{ base: "95%", md: "92%" }}>
                                    {results.length > 0 ? (
                                        <>
                                            <Text textAlign="left" mb={6}>Displaying {fromIdx}-{toIdx} results out of {totalElements} for <b>{query}</b></Text>

                                        <Box>
                                            {results.map((result) => (
                                                <>
                                                <Box
                                                    key={result.id}
                                                    display="flex"
                                                    alignItems="center"
                                                    overflow="hidden"
                                                >
                                                    <Box flex="0 0 auto" width="200px" height="120px" mr={4}>
                                                        <Image
                                                            src={result.images[0] || 'https://via.placeholder.com/120x80'}
                                                            alt={result.title}
                                                            width="100%"
                                                            height="100%"
                                                            objectFit="cover"
                                                        />
                                                    </Box>

                                                    {/* Content Section */}
                                                    <Box flex="1">
                                                        <Text fontWeight="bold" fontSize="lg" mb={2} noOfLines={1} textAlign="left">
                                                            {result.title}
                                                        </Text>
                                                        <Text fontSize="md" color="gray.400" mb={1} textAlign="left">
                                                            {formatDate(result.publishedAt)}
                                                        </Text>
                                                        <Text fontSize="md" noOfLines={2} textAlign="left">
                                                            {result.description}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                                    <Divider mt={2} mb={2}/>
                                                </>
                                            ))}
                                            <Box display="flex" justifyContent="center" mt={4}>
                                                <Button
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </Button>
                                                <Button
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                    ml={4}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </Box>
                                        </>
                                    ) : (
                                        <Text>No results found</Text>
                                    )}
                                </Box>
                            </VStack>
                        </Grid>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

