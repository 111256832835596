import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {Tag} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {fetchAuthHeaders} from "../../utils/authHeaders";

interface TagState {
    tags: Tag[];
    selectedTags: Tag[];
    isLoading: boolean;
    error: string | null;
}

const initialState: TagState = {
    tags: [],
    selectedTags: [],
    isLoading: false,
    error: null,
};

export const fetchTagsForUser = createAsyncThunk(
    'tags/fetchTagsForUser',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/tagsForUser?locale=${locale}`,{
                headers: headers,
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch user interests');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchTags = createAsyncThunk(
    'tags/fetchTags',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/tags?locale=${locale}`,{
                headers: headers,
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch tags');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const modifyUserTag = createAsyncThunk(
    'tags/modifyUserTag',
    async ({ tagCode, actionType }: { tagCode: string; actionType: 'follow' | 'unfollow' }, { dispatch, getState, rejectWithValue }) => {
        try {
            //dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState); // Includes the auth token
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/tags/${tagCode}/${actionType}`,
                null, // No request body needed
                { headers }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || `Failed to ${actionType} tag`);
        } finally {
            //dispatch(stopLoading());
        }
    }
);


export const tagSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch tags for User reducers
            .addCase(fetchTagsForUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTagsForUser.fulfilled, (state, action: PayloadAction<Tag[]>) => {
                state.isLoading = false;
                state.selectedTags = action.payload;
            })
            .addCase(fetchTagsForUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Fetch tags reducers
            .addCase(fetchTags.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTags.fulfilled, (state, action: PayloadAction<Tag[]>) => {
                state.isLoading = false;
                state.tags = action.payload;
            })
            .addCase(fetchTags.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Add user to tag reducers
            .addCase(modifyUserTag.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(modifyUserTag.fulfilled, (state, action: PayloadAction<Tag>) => {
                debugger;
                //state.isLoading = false;
                const tag = action.payload;

                // Check if the tag is already in selectedTags
                const isTagSelected = state.selectedTags.some((t) => t.code === tag.code);

                // Determine action based on whether the tag is currently selected
                if (isTagSelected) {
                    // If tag is already selected, remove it (unfollow)
                    state.selectedTags = state.selectedTags.filter((t) => t.code !== tag.code);
                } else {
                    // If tag is not selected, add it (follow)
                    state.selectedTags.push(tag);
                }
            })
            .addCase(modifyUserTag.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});


export default tagSlice.reducer;
