import {Box, Image, Text, useColorModeValue, useToast} from "@chakra-ui/react";
import { useState } from "react";
import { Source } from "../../types";

interface SourceBoxProps {
    source: Source;
    isSelected: boolean;
    onSelectTag: (Source: Source, isSelected: boolean) => void;
}

const SourceBox: React.FC<SourceBoxProps> = ({ source, isSelected, onSelectTag }) => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const borderColor = useColorModeValue("light.highlight", "dark.highlight");
    const selectedBorderColor = useColorModeValue('brand.600', 'brand.600');
    const textColor = useColorModeValue("inherit", isSelected ? "black" : "white");

    const toast = useToast();

    const handleSelect = async () => {
        debugger;
        try {
            onSelectTag(source, !isSelected); // Pass the event up to parent
        } catch (error) {
            toast({
                title: `Error selecting ${source.name}`,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

    return (
        <Box
            as="button"
            borderWidth="1px"
            borderRadius="md"
            p={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={handleSelect}
            _hover={{ borderColor: borderColor, cursor: "pointer" }}
            borderColor={isSelected ? selectedBorderColor : "gray.200"}
            bg={isSelected ? "brand.50" : bgColor}
            width="100%"
        >
            {/* Icon */}
            <Image src={source.icon} alt={source.name} width={50} mb={2} />

            {/* Code - Small Text */}
            <Text fontWeight="light" fontSize="sm" color={textColor} textAlign="center" mb={1}>
                @{source.code}
            </Text>

            {/* Name - Bold Text */}
            <Text fontWeight="bold" fontSize="md" color={textColor} textAlign="center">
                {source.name}
            </Text>
        </Box>
    );
};

export default SourceBox;
